.CustomModal .modal-dialog {
  max-width: fit-content;
  min-width: 440px;
  text-align: center;
}

.CustomModal .modal-header {
  border: none;
  padding: 15px 25px;
}

.CustomModal .modal-body {
  padding: 0 27px 42px;
}

.CustomModal-title {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 31px;
}

.CustomModal-legend {
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 23px;
}

.CustomModal-img-container {
  text-align: center;
}

.CustomModal-buttons-container .btn {
  font-weight: 100;
  min-width: 181px;
  text-align: center;
}

.CustomModal.single .single-button,
.double-button,
.CustomModal.import-success .single-button,
.CustomModal.import-error .single-button,
.double-button {
  margin-top: 0;
}

.CustomModal.import-success .single-button,
.CustomModal.import-error .single-button,
.double-button {
  margin-top: 28px;
}

.CustomModal-caption {
  font-size: 16px;
  font-weight: 400;
  max-width: 320px;
  margin: 0 auto;
  margin-top: 20px;
}

.single-button,
.double-button {
  max-width: 385px;
  margin: 0 auto;
  margin-top: 50px;
}

.double-button {
  display: flex;
  justify-content: space-between;
}

.spinner {
  width: 16px;
  height: 16px;
  fill: var(--bento-red);
  margin-right: 10px;
  margin-top: -2px;
  transform-origin: center;
  animation: spinner 1000ms linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
