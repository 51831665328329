.Input {
  margin-bottom: 10px;
}

.InputContainer {
  text-align: right;
}

.labelContainer {
  display: flex;
  justify-content: space-between;
}

.labelContainer label {
  font-weight: 400;
}

.labelContainer .required {
  font-size: 12px;
}

.requiredChar {
  display: inline-block;
  margin-top: 5px;
  font-size: 12px;
  font-style: italic;
  color: var(--text-gray);
}