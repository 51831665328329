/* Fonts */

@font-face {
  font-family: 'AvenirNext';
  font-style: italic;
  font-weight: 300;
  src: local('AvenirNext'),
    url('../../fonts/avenir/AvenirLTStd-Oblique.eot') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/avenir/AvenirLTStd-Oblique.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/avenir/AvenirLTStd-Oblique.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/avenir/AvenirLTStd-Oblique.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/avenir/AvenirLTStd-Oblique.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 300;
  src: local('AvenirNext'),
    url('../../fonts/avenir/AvenirLTStd-Roman.eot') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/avenir/AvenirLTStd-Roman.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/avenir/AvenirLTStd-Roman.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/avenir/AvenirNext-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/avenir/AvenirLTStd-Roman.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 400;
  src: local('AvenirNext'),
    url('../../fonts/avenir/AvenirNextLTPro-Demi.eot') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/avenir/AvenirNextLTPro-Demi.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/avenir/AvenirNextLTPro-Demi.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/avenir/AvenirNext-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/avenir/AvenirNextLTPro-Demi.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 500;
  src: local('AvenirNext'),
    url('../../fonts/avenir/AvenirNextLTPro-Bold.eot') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/avenir/AvenirNextLTPro-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/avenir/AvenirNextLTPro-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/avenir/AvenirNextLTPro-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/avenir/AvenirNextLTPro-Bold.svg') format('svg');
}

@font-face {
  font-family: 'AvenirNext-Regular';
  src: url('../../fonts/avenir/AvenirNext-Regular.ttf');
}
