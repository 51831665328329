.error-container {
  padding: 4rem 8rem;
}

.error-container h2 {
  margin: 1rem 0px;
}

.error-message {
  display: flex;
  gap: 2rem
}
.error-message img {
  width: 300px;
}