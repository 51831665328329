@import url('./assets/css/theme.min.css');
@import url('./assets/css/modules/fonts.css');
@import url('./assets/css/modules/titles.css');
@import url('./assets/fonts/feather/feather.min.css');

:root {
  /*Body*/
  --body-bg: #f8f8f8;

  /*Reading Text*/
  --text-black: #202020;
  --text-gray: #858585;

  /*Titles*/
  --title-size-h1: 37px;
  --title-size-h2: 27px;
  --title-size-h3: 27px;

  /* Text */
  --text-lg: 18px;
  --text-md: 15px;
  --text-sm: 13px;

  /* Colors */
  --bento-red: #ac183c;
  --bento-blue: #3c6384;
  --bento-light-blue: #a8d4ff50;
}

body {
  margin: 0;
  font-family: 'AvenirNext', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-black);
  font-weight: 300;
  background-color: var(--body-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #dbdbdb;
}

.btn-txt {
  position: relative;
  top: 1px;
}

.btn-custom.bold {
  font-weight: 400;
}

.btn-custom.regular {
  font-weight: 300;
}

/* React Boostrap Table Classes */

.custom-thead tr,
.custom-thead tr th {
  background-color: var(--bento-red);
  border: none;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}

.custom-row {
  font-size: 13px;
  background: white;
  border-bottom: 1px solid #dfdfdf;
}

.custom-row td {
  border: none;
}

.custom-th-lg {
  width: 23%;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:disabled {
  cursor: not-allowed;
}

.font-size-18 {
  font-size: 18px;
}

.bg-blue {
  background-color: var(--bento-blue);
}

.bg-black {
  background-color: #000000;
}

.bg-blue-400 {
  background-color: var(--bento-blue);
}
.bg-gray-200 {
  background-color: #d8d8d8;
}

.bg-pink {
  background-color: #f5bdc5;
}

.bg-blue-100 {
  background-color: #a8d3ff;
}

.not-hover.btn-primary,
.not-hover:hover {
  border: none !important;
  padding: 0 !important;
  color: #000 !important;
  background-color: #d8d8d8 !important;
}

.custom-text-wrap {
  white-space: normal;
}

.table-responsive-xl {
  width: 100%;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-24 {
  font-size: 24px;
}
