/* Titles */

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  position: relative;
  top: 3px; /* Line Height center hack */
}

.section-title {
  background-color: white;
}

.title-h1 {
  font-size: var(--title-size-h1);
  color: var(--text-black);
  font-weight: 400;
}

.title-h2 {
  font-size: var(--title-size-h2);
  color: var(--text-black);
  font-weight: 400;
}

.title-h3 {
  font-size: var(--title-size-h3);
  color: var(--text-black);
  font-weight: 400;
}
