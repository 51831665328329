.Login {
  width: 100vw;
  height: 100vh;
  background-image: url('/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.Login-container {
  display: flex;
  margin-top: 65px;
}

.Login-info {
  color: black;
}

.Login-info-logo {
  margin-bottom: 34px;
  display: flex;
  align-items: center;
}

.Login-info-logo span {
  padding-left: 15px;
  position: relative;
  top: 2px;
}

.Login-info-welcome {
  font-size: 15px;
  margin-bottom: 13px;
  font-weight: 100;
}

.Login-info-title {
  font-size: 35px;
  font-weight: 100;
  margin-bottom: 12px;
}

.Login-info-subtitle {
  font-size: 25px;
  font-weight: 100;
}

.Login-form-header {
  padding: 17px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login-form-title {
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  padding: 17px 0 0 0;
}

.Login-form-title span {
  display: block;
  text-align: right;
  font-size: 12px;
  font-weight: 100;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 10px;
}

.signup-responsive {
  padding: 0px;
}

.video-container {
  display: none;
}
@media (min-width: 431px) {
  .signup-responsive {
    padding: 2.25rem;
  }
  .video-container {
    display: flex;
  }
}
