.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.spinner-container {
  padding: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cut-text { 
  text-overflow: ellipsis;
  overflow: hidden; 
  max-width: 300px; 
  height: 1.2em; 
  white-space: nowrap;
}

.invisible-header {
  height: 200px;
}

.summary-container .input-group>.form-control:focus {
  z-index: -1 !important;
}