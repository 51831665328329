.RequestMessage-message {
  background-color: white;
  padding: 32px;
  width: 100%;
}

@media (min-width: 431px) {
  .RequestMessage-message {
    width: 50%;
  }
}

.RequestMessage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.RequestMessage-header {
  margin-bottom: 38px;
  font-size: 22px;
  font-weight: 300;
}

.RequestMessage-mesage {
  font-size: 18px;
  margin-bottom: 38px;
}

.RequestMessage-button-container button {
  background-color: #791932;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 0;
  display: inline-block;
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 100;
  transition: 200ms ease-in-out;
}

.button-text {
  position: relative;
  top: 2px;
}

.RequestMessage-button-container button:hover,
.RequestMessage-button-container button:active {
  background-color: #2bbbad !important;
}

.mail-link {
  color: #792532;
}
